<template>
    <v-container id="secHomeEquipment">
        <h1 
            class="text-h6 text-md-h4 mt-6 mt-lg-10 mb-lg-6 text-center text-uppercase font-weight-black"
            data-aos="fade-down"
            data-aos-duration="1500"
            data-aos-delay="100"
            data-aos-offset="300"
        >
            Equipos de 
            <span class="primary--text">
                fresado
            </span>
        </h1>
        <div class="line-after-iso mb-12 primary"></div>
        <div class="line-title-equipment primary mx-auto"></div>
        <p
            class="text-eq-home text-h4 text-body-1 text-center mx-auto mt-3 mb-10 font-weight-light"
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay="100"
            data-aos-offset="300"
        >
            {{ text }}
        </p>

        <div id="carouselFresar">
            <section>
                <vue-horizontal-list :items="items" :options="options">
                    <template v-slot:nav-prev>
                    <v-img :src="require('@/assets/icons/prev-arrow.svg')" />
                    </template>

                    <template v-slot:nav-next>
                    <v-img :src="require('@/assets/icons/next-arrow.svg')" />
                    </template>

                    <template v-slot:default="{ item }">
                        <v-card class="w-responsive">
                            <v-img :src="item.img" />
                            <v-card-title class="primary white--text text-uppercase mb-4">
                                <div class="card-equip">
                                    <div class="title-equip">
                                        <div class="line-equip"></div>
                                        <div class="title-text">
                                            <h3 class="text-center font-weight-black text-subtitle-1 text-md-h6 text-xl-h5">{{ item.marca }}</h3>
                                            <h3 class="text-center font-weight-black text-subtitle-1 text-md-h6 text-xl-h5">{{ item.modelo }}</h3>
                                        </div>
                                        <div class="line-equip"></div>
                                    </div>
                                    <div class="descrip-equip px-1 px-sm-4 px-lg-6">
                                        <h4 class="font-weight-black text-white text-subtitle-2 text-md-subtitle-1 text-xl-h5 space-pre">Ancho de corte</h4>
                                        <div class="line-equipment"></div>
                                        <p class="">{{ item.width }}</p>
                                        <h4 class="font-weight-black text-white text-subtitle-2 text-md-subtitle-1 text-xl-h5 space-pre">espesor máximo de fresado</h4>
                                        <div class="line-equipment"></div>
                                        <p class="">{{ item.density }}</p>
                                    </div>
                                </div>
                            </v-card-title>
                        </v-card>
                    </template>
                </vue-horizontal-list>
            </section>
        </div>
    </v-container>
</template>

<script>
import Vue from "vue";
import VueHorizontalList from "../../../node_modules/vue-horizontal-list/src/vue-horizontal-list";

export default Vue.extend({
    name: 'SectionHomeEquipment',
    components: {
        VueHorizontalList,
    },
    data() {
        return {
            text: 'Para la atención de nuestros clientes contamos con nueve (9) equipos de fresado de diferentes anchos, igualmente ofrecemos el servicio de microfresado con un tambor de dos (2) metros de ancho que montamos en una maquina Wirtgen W 2000.',
            options: {
                responsive: [
                { end: 576, size: 1 },
                { start: 576, end: 768, size: 2 },
                { start: 768, end: 992, size: 3 },
                { size: 3 },
                ],
                list: {
                // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
                windowed: 1200,

                // Because: #app {padding: 80px 24px;}
                padding: 24,
                },
                position: {
                start: 2,
                },
                autoplay: { play: true, repeat: true, speed: 3000 },
            },
            items: [
                {
                    img: require('@/assets/img/img-wirtgen-1000.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 1000',
                    width: '1.00 m.',
                    density: '20 cm.'
                },
                {
                    img: require('@/assets/img/img-wirtgen-2000.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 2000',
                    width: '2.00 m.',
                    density: '30 cm.'
                },
                {
                    img: require('@/assets/img/img-wirtgen-100.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 100',
                    width: '1.00 m.',
                    density: '30 cm.'
                },
                {
                    img: require('@/assets/img/img-wirtgen-150.jpg'),
                    marca: 'Wirtgen',
                    modelo: 'W 150',
                    width: '1.50 m.',
                    density: '30 cm.'
                },
                {
                    img: require('@/assets/img/img-caterpillar.jpg'),
                    marca: 'CATERPILLAR',
                    modelo: 'PR-450',
                    width: '2.00 m.',
                    density: '25 cm.'
                },
            ],
        }
    },
})
</script>

<style lang="scss">
#secHomeEquipment {
    .line-after-iso { 
        position: relative;
        border-top: 1px solid;
        width: 10%;
        margin: auto;
    }
    .carousel-equipment {
        width: 70%;
        margin: auto;
        .line-title-equipment, .line-equipment {
        position: relative;
        border-top: 1px solid;
        width: 8%;
        }
        // .grid-responsive { width: 100%; }
    }
    .text-eq-home { padding: 0 22vw; }
    .card-equip {
        width: 100%;
        .title-equip {
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 0 2vw;
            .title-text { padding: 0 1vw; }
            .line-equip {
                height: 4vw;
                width: 3px;
                background-color: white;
                transform: skewX(160deg);
            }
        }
        .descrip-equip { 
            margin-top: 2vw;
            .space-pre { word-break: keep-all; }
            .line-equipment {
                position: relative;
                border-top: 1px solid;
                width: 8%;
            }
        }

        @media (max-width: 1024px) {
            /* .descrip-equip {
                padding: 0;
            } */
        }
        @media (max-width: 800px) and (min-width: 501px) {
            .title-equip {
                .line-equip {
                    height: 8vw;
                }
            }
        }
        @media (max-width: 500px) {
            .title-equip {
                .title-text { padding: 0 10vw;}
                .line-equip {
                    height: 12vw;
                }
            }   
        }
    }

    #carouselFresar {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
        padding: 80px 24px;
        .vhl-btn-left { margin-left: -82px; }
        .vhl-btn-right { margin-right: -82px; }
        .vhl-btn-left, .vhl-btn-right {
            width: 55px;
            height: 55px;
            box-shadow: none;
        }

        @media (min-width: 1200px) {
            padding-left: 80px;
            padding-right: 80px;
        }
    }

}
</style>